import { useEffect } from "react";

export const PaymentSuccess = () => {
  useEffect(() => {
    if (window.rdt) {
      // Trigger the PageVisit event only when this component mounts
      window.rdt("track", "PageVisit");
    }
  }, []);
  return <h1 className="text-xl font-bold text-center p-5">Payment Successfully Completed</h1>;
};
export const PaymentFailure = () => {
  return <h1 className="text-xl font-bold text-center p-5 text-red-500">Payment failed </h1>;
};
