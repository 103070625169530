const { useState, useRef } = require("react");
import logoCT from "assets/images/logos/Al-Connect horizontal white Logo.svg";
import toast from "react-hot-toast";
import emailjs from "emailjs-com";
export const ResponseForm = () => {
  const imgRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    degree: "",
    college: "",
    experience: "",
    placeOfOrigin: "",
    about: "",
    academicCredentials: "",
    expertise: "",
    servicesOffered: "",
    daysAvailable: {
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    },
    timeSlots: "",
    faq1: "",
    faq2: "",
    faq3: "",
    phoneNumber: "",
    country: "",
    email: "",
    profileImage: "",
  });

  const sendConfirmationEmail = async () => {
    emailjs.init("K8E8Z0gg1HyXvAUTQ");
    const template = {
      to_name: formData.name,
      to_email: formData.email,
    };
    try {
      const res = await emailjs.send(
        "service_4u2onep",
        "template_amfbzv6",
        template,
        "K8E8Z0gg1HyXvAUTQ"
      );
      return true;
    } catch (error) {
      console.error("Failed to send email. Error:", error);
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type == "file") {
      setFormData((prev) => ({
        ...prev,
        profileImage: files,
      }));
      imgRef.current = files[0];
    }
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        daysAvailable: {
          ...prev.daysAvailable,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    toast.loading("Loading...", {
      id: 1,
    });
    sendConfirmationEmail();
    const formInfo = {};
    Object.keys(formData).map((key) => {
      if (
        key != "faq1" &&
        key != "faq2" &&
        key != "faq3" &&
        key != "profileImage" &&
        key != "servicesOffered"
      ) {
        formInfo[key] = formData[key];
      }
    });
    const faq = [
      {
        question: " Why are  the consultants names hidden?",
        answer: formData.faq1,
      },
      {
        question:
          "   Why did you choose your degree / field of study and how it has/will shaped/shape your career path?",
        answer: formData.faq2,
      },
      {
        question: "Why did you choose the college you did your degree from?",
        answer: formData.faq3,
      },
    ];
    formInfo["faqs"] = faq;

    formInfo["imageUrls"] = [formData["profileImage"]];

    const servicesOffered = formData.servicesOffered.split(",");
    formInfo["servicesOffered"] = servicesOffered;
    // console.log(formInfo, "formInfo");
    e.preventDefault();
    try {
      const res = await fetch("https://al-connect-production.up.railway.app/consultants/create", {
        method: "POST",
        body: JSON.stringify(formInfo),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.ok) {
        toast.success("Successfully Submitted", {
          id: 1,
        });
      } else {
        toast.error("Invalid Credentials...", {
          id: 1,
        });
      }
    } catch (e) {
      toast.error("Error while submitting..", {
        id: 1,
      });
    }
  };

  return (
    <div className="flex justify-center items-center  flex-col mt-16 mb-10">
      <form
        className="flex flex-col text-lg items-center justify-center shadow-2xl rounded-2xl p-4 min-w-[35rem] max-w-[40rem]"
        onSubmit={handleSubmit}
      >
        <Header />
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Name</div>
          <input
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter Your Name"
            className="w-full px-4 py-1  border rounded border-slate-300"
          />
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl  font-bold text-left py-2">Degree Name</div>
          <input
            name="degree"
            value={formData.degree}
            onChange={handleChange}
            placeholder="Enter Degree Name"
            className="w-full px-4 py-1 border rounded border-slate-300"
          />
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">College/University Name</div>
          <input
            name="college"
            value={formData.college}
            onChange={handleChange}
            placeholder="Enter Your College or University Name"
            className="w-full px-4 py-1 border rounded border-slate-300"
          />
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Phone number</div>
          <input
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Enter Your Phone no"
            className="w-full px-4 py-1 border rounded border-slate-300"
          />
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Email</div>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter Your Email"
            className="w-full px-4 py-1 border rounded border-slate-300"
          />
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Profile Picture</div>
          <input
            type="text"
            name="profileImage"
            value={formData.profileImage}
            onChange={handleChange}
            placeholder="Enter Profile Picture URL"
            className="w-full px-4 py-1 border rounded border-slate-300"
          />
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Country</div>
          <input
            name="country"
            value={formData.country}
            onChange={handleChange}
            placeholder="Enter Your Country Name"
            className="w-full px-4 py-1 border rounded border-slate-300"
          />
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Years of Experience</div>
          <input
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            placeholder="Enter Your Experience"
            className="w-full px-4 py-1 border rounded border-slate-300"
          />
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Place of Origin</div>
          <input
            name="placeOfOrigin"
            value={formData.placeOfOrigin}
            onChange={handleChange}
            placeholder="Enter the Place of Origin"
            className="w-full px-4 py-1 border rounded border-slate-300"
          />
        </div>

        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">About</div>
          <textarea
            name="about"
            value={formData.about}
            onChange={handleChange}
            placeholder="Enter About yourself"
            className="w-full px-4 py-1 border rounded border-slate-300"
            rows="4"
          ></textarea>
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Academic Credentials</div>
          <textarea
            name="academicCredentials"
            value={formData.academicCredentials}
            onChange={handleChange}
            placeholder="Enter about your Academic Credentials"
            className="w-full px-4 py-1 border rounded border-slate-300"
            rows="4"
          ></textarea>
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Experience and Expertise</div>
          <textarea
            name="expertise"
            value={formData.expertise}
            onChange={handleChange}
            placeholder="Enter about your Experience and Expertise"
            className="w-full px-4 py-1 border rounded border-slate-300"
            rows="4"
          ></textarea>
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Consultation Services Offered</div>
          <textarea
            name="servicesOffered"
            value={formData.servicesOffered}
            onChange={handleChange}
            placeholder="Consultation services offered (separated by comma)"
            className="w-full px-4 py-1 border rounded border-slate-300"
            rows="4"
          ></textarea>
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Days Available</div>
          <div className=" grid grid-cols-4 px-2">
            {Object.keys(formData.daysAvailable).map((day) => (
              <label key={day} className="flex text-lg items-center py-1">
                <input
                  type="checkbox"
                  name={day}
                  checked={formData.daysAvailable[day]}
                  onChange={handleChange}
                  className="mr-2"
                />
                {day}
              </label>
            ))}
          </div>
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">Time Slots Available</div>
          <input
            name="timeSlots"
            value={formData.timeSlots}
            onChange={handleChange}
            placeholder="Enter the Time Slots Available"
            className="w-full px-4 py-1 border rounded border-slate-300"
          />
        </div>
        <div className="mb-4 w-full">
          <div className="text-xl font-bold text-left py-2">FAQs</div>
          <div className=" pl-5">
            <div className="text-lg font-bold text-left py-2">
              Why did the consultants names hidden?
            </div>
            <input
              name="faq1"
              value={formData.faq1}
              onChange={handleChange}
              placeholder="Enter your answer here"
              className="w-full px-4 py-1 border rounded border-slate-300"
            />
            <div className="text-lg font-bold text-left py-2">
              Why did you choose your degree / field of study and how it has/will shaped/shape your
              career path?
            </div>
            <input
              name="faq2"
              value={formData.faq2}
              onChange={handleChange}
              placeholder="Enter your answer here"
              className="w-full px-4 py-1 border rounded border-slate-300"
            />
            <div className="text-lg font-bold text-left py-2">
              Why did you choose the college you did your degree from?
            </div>
            <input
              name="faq3"
              value={formData.faq3}
              onChange={handleChange}
              placeholder="Enter your answer here"
              className="w-full px-4 py-1 border rounded border-slate-300"
            />
          </div>
        </div>
        <button type="submit" className=" py-4 px-16 bg-orange-600 text-white rounded">
          Submit
        </button>
      </form>
    </div>
  );
};
const Header = () => {
  return (
    <div className="flex w-full items-center justify-center cursor-pointer  bg-orange-600 rounded-lg px-5 py-6 mb-5">
      <div className=" w-[50%]">
        <img src={logoCT} />
      </div>
      <div className=" w-[50%] text-white font-bold text-2xl">Form Response</div>
    </div>
  );
};
